const hamburger = {
  init: function() {
    $('body').on('click', '.hamburger', function() {
      if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('.nav-bar .nav').removeClass('show');
      } else {
        $(this).addClass('active');
        $('.nav-bar .nav').addClass('show');
      }
      return false;
    });
  }
}



export const student_dropdown = {
  init: function() {
    $('body').on('click', '.user-dropdown', function() {
      if($(this).hasClass('open')) {
        $('#student_dropdown_modal').hide();  
        $(this).removeClass('open');
      } else {
        $('#student_dropdown_modal').show();  
        $(this).addClass('open');
      }
      
      //return false;
    });
    
  }
}

export const home = {
  init: function() {
    hamburger.init();
  }
}

export const person_info = {
  init: function() {
    this.open_event();
    this.close_event();
  },

  open_event: function() {
    $('body').on('click', '.people a', function() {
      const key = $(this).attr('data-key');
      
      //$("#person_info_modal .pic").css("background-image", "url(" + $(this).attr('data-avatar') + ")");

      $('#person_info_modal .pic img').attr('src', $(this).attr('data-avatar'));
      $('#person_info_modal .name').html($(this).attr('data-relation'));
      $('#person_info_modal .text').html(all_people[key].bio);

      
      $('#person_info_modal').show();
      return false;
    });
  },

  close_event: function() {
    $('#person_info_modal .close-modal').bind('click', function() {
      $('#person_info_modal').hide();
      return false;
    });
  }

}

export const switch_scene = {
  init: function() {
    $('body').on('click', '.switch-scene a', function() {
      const scene_number = $(this).attr('data-scene-number');      
      $('.user-dropdown').trigger('click');

      $([document.documentElement, document.body]).animate({
          scrollTop: $(`#${scene_number}`).offset().top - 70
      }, 2000);
      return false;
    });
  }
}